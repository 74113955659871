<template>
    <modal ref="modaAdministrarCategoria" titulo="Categoría de los premios" tamano="modal-lg" :no-aceptar="true" :no-cancelar="true">
        <div class="row mx-0">
            <div class="col-auto px-3" />
            <div class="col">
                <div class="row mx-0 mb-3">
                    <div class="col-12 px-0 text-general f-15">
                        Define las categorías o los grupos de los premios que pueden redimir los usuarios
                    </div>
                </div>
                <div class="row mx-0 align-items-end mt-4 d-middle">
                    <div class="col-5 px-0">
                        <p class="text-general pl-3 f-12">
                            Nombre de la categoría
                        </p>
                        <ValidationObserver ref="form">
                            <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre">
                                <el-input v-model="nombre" placeholder="Nombre" class="w-100" size="small" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </ValidationObserver>
                    </div>
                    <el-tooltip placement="bottom" content="Crear tipo" effect="light">
                        <div class="btn-red ml-2" @click="crearCategoria">
                            <i class="icon-plus f-18" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="row mx-0 py-4" />
                <p class="text-general f-15 mb-3">
                    Lista de categorías
                </p>
                <div v-for="(cat,index) in datos" :key="index" class="row mx-0 align-items-center mb-3">
                    <div class="col-5 pl-2">
                        <el-input v-model="cat.nombre" :disabled="cat.id!=categoria" maxlength="60" class="w-100" size="small" />
                    </div>
                    <div v-if="categoria!=cat.id" :style="`${activo || (categoria == cat.id) ? '':'opacity:0.5;pointer-events:none;'}`" class="btn-action border" @click="habilitar(cat.id)">
                        <i class="icon-pencil-outline" />
                    </div>
                    <div v-if="cat.id==categoria" class="btn-action border" @click="actualizar(cat.id,cat.nombre)">
                        <i class="icon-ok-circled-outline text-green f-20" />
                    </div>
                    <div v-if="activoBorrar!=cat.id" class="btn-action border ml-2" @click="habilitarBorrar(cat.id)">
                        <i class="icon-delete-outline f-22" />
                    </div>
                    <div v-if="cat.id==activoBorrar" class="btn-action border ml-2" @click="borrar(cat.id)">
                        <i class="icon-ok-circled-outline text-green f-20" />
                    </div>
                    <el-tooltip placement="bottom" content="Premios dentro de esta categoria" effect="light">
                        <div class="pill text-white d-middle-center br-20 px-2 ml-3">
                            <i class="icon-gift-outline f-22" />
                            <span class="ml-4">{{ cat.contador }}</span>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="col-auto px-3" />
        </div>
    </modal>
</template>

<script>
import GamingProductos from '@/services/gaming/gamingProductos'
export default {
    data(){
        return {
            activo: true,
            nombre: null,
            categoria_lista: '',
            datos: [],
            categoria: null,
            activoBorrar: null
        }
    },
    methods: {
        toggle(){
            this.getListaCategorias()
            this.nombre=null
            this.$refs.modaAdministrarCategoria.toggle();   
        },
        abrirModalEliminar(){ 
            this.$refs.modalEliminar.toggle()
        },
        async getListaCategorias(){
            try {
                const { data } = await GamingProductos.getCategoriasGamingProducto()
                this.datos = data.data


                console.log(this.datos)
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearCategoria(){
            try {
                
                let validate = await this.$refs.form.validate()
                if(!validate) return this.notificacion("","Escriba un nombre de categoría valido","warning")

                let params = {
                    nombre: this.nombre
                }
                const { data } = await GamingProductos.crearCategoriaGamingProducto(params)

                this.nombre = ''
                this.getListaCategorias()
                this.notificacion("","Categoría creada correctamente","success")
                
            } catch (e){
                this.error_catch(e)
            }
        },
        habilitar(idCategoria){
            this.activo = false
            this.categoria = idCategoria
        },
        async actualizar(idCategoria,nuevoNombre){
            try {
                
                if(!nuevoNombre){
                    return this.notificacion("","No es posible guardar la categoría sin nombre")   
                }
                
                if(nuevoNombre.length>60){
                    return this.notificacion("","El campo nombre debe tener máximo 60 carateres")
                }

                let params = {
                    nombre: nuevoNombre,
                    id_categoria: idCategoria
                }

                const { data } = await GamingProductos.editarCategoriaGamingProducto(params)
                this.categoria = null

                this.notificacion("","Categoría actualizada correctamente","success")
                this.activo = true
            } catch (e){
                this.error_catch(e)
            }
        },
        habilitarBorrar(idCategoria){
            this.activoBorrar = idCategoria
        },
        async borrar(idCategoria){
            try {
                const { data } = await GamingProductos.borrarCategoriaGamingProducto(idCategoria)
                this.getListaCategorias()
                this.notificacion("","Categoría eliminada correctamente","success")
                this.activoBorrar = null

            } catch (error){
                this.error_catch(error)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
.pill{
    background-color: #000000;
    height: 28px;
    min-width: 70px;
}
</style>